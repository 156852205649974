<template>
  <PageContainer
    :title="$translations.contracts.title"
    :back-enabled="false"
    :ellipsis="true"
    :back="back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <template v-if="contract">
            <HtmlViewer
              :html-content="contract.content"
              class="body margin-bottom-xl padding-bottom"
            />
          </template>
          <template v-if="loading">
            <Skeleton
              class="margin-bottom"
              width="100%"
              height="64px"
              border-radius="16px"
            />
            <Skeleton width="100%" height="300px" border-radius="16px" />
          </template>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  HtmlViewer,
  PageContainer,
  Skeleton,
  SpeContracts
} from '@seliaco/red-panda'

export default {
  components: {
    PageContainer,
    Skeleton,
    HtmlViewer
  },
  data () {
    return {
      loading: true,
      contract: null,
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.push({
              path: this.$route.query.back
            })
          } else {
            this.$router.push({
              name: 'PatientDetail',
              params: {
                id: this.$route.params.patientId
              }
            })
          }
        }
      }
    }
  },
  created () {
    SpeContracts.getContract(this.$route.params.patientId)
      .then((body) => {
        this.contract = body.contract
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

<style lang="sass" scoped>
.checkbox-container
  border-bottom: 1px solid var(--gray-10)
  margin-bottom: 16px
</style>
